<template>
  <div>
    <center>
      <h2>
        Frequently<v-tooltip bottom
          ><template v-slot:activator="{ on, attrs }"
            ><span v-bind="attrs" v-on="on">*</span></template
          ><span
            >To be perfrectly frank, I'm just guessing what kind of questions
            might be frequently asked. I haven't actually frequently been asked
            these because I'm only developing this now.</span
          ></v-tooltip
        >
        Asked Questions
      </h2>
    </center>
    <v-expansion-panels focusable multiple class="mt-3">
      <v-expansion-panel v-for="(item, index) in faqs" :key="index">
        <v-expansion-panel-header>
          {{ item.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-html="item.text" class="pa-3" />
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {};
  },
  computed: {
    faqs() {
      return [
        {
          title: "What is 'Penny For Your Thoughts'?",
          text: "To be honest, that's the kind of question that's better answered by the 'About' page, but the long and short of it: Somebody writes a message, like a love letter or something.  This program splits it up into pieces and generates a code for each piece.  The codes are scanned (usually one each day or something like that) to uncover the message over time.",
        },
        {
          title: "How much does it cost?",
          text: "The cost is driven by two things: 1) How many QR codes are generated, and 2) Whether or not you're printing the QR codes yourself.  Each QR costs $0.01 USD (with a minimum of $5.00), and if you want the QR codes printed/mailed to you, it's an additional $0.01 USD for each QR with a minimum of $1.00 - plus shipping, which is $1.50 domestic (US), and $3.00 international.",
        },
        {
          title: "How do I use it?",
          text: "If you choose the 'Create Message' option on the home page, it'll walk you through the steps: You'll write your message, choose how to split it into pieces, choose some settings for how it's used, enter your email address, and pay.  You can have the QR codes printed and mailed to you, or you can get a PDF and print them yourself.  You'll also get a 'login' QR code: this will allow the person receiving the gift to log in and begin scanning the QR codes that will uncover the message.  Stick the labels to a bunch of pennies, and give them to the recipient with the Login QR, and you're good to go.  You can see their progress when you login through the link that gets sent to your email.",
        },
        {
          title: "Is it secure?",
          text: "Well, depends on what you mean.  Is anybody going to have unauthorized access to your letter/message?  No.  It's quite secure in that regard, stuff is well locked-down.  If, however, somebody has your login QR (such as the person you're giving the gift to, who is supposed to have it), they could probably extract the whole message if they were smart and determined - so I can't recommend using this to slowly reveal your dying, multi-billion-dollar will to competing factions among your children or something.",
        },
        {
          title: "What if I have a question that wasn't answered here?",
          text: "<span>If that happens, please reach out for support at <a href='mailto:pfyt@gmail.com'>pfyt@gmail.com</a> and I'll get back to you as soon as I can.</span>",
        },
        // {
        //   title: "",
        //   text: "",
        // },
        // {
        //   title: "",
        //   text: "",
        // },
      ];
    },
  },
};
</script>
